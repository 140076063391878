import { IdParam } from '@acrelec-cloud/apico-cdk';
import { ProductObject, ProductType, RestaurantOutageProductsObject } from '@acrelec-cloud/apico-sdk';
import { observer } from 'mobx-react-lite';
import { applySnapshot } from 'mobx-state-tree';
import React, { DependencyList, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { ActionType, AddToCartButton } from 'src/components/Products/AddToCart/AddToCartButton';
import { useCategoryNavigation } from 'src/components/Products/Navigation/category-navigation.hook';
import { PartsContainer } from 'src/components/Products/Parts/PartsContainer';
import { emptyProduct } from 'src/components/Products/Product/empty-product.confg';
import { ProductInfo } from 'src/components/Products/Product/ProductInfo';
import { useStore } from 'src/contexts/store.context';
import { useMoney } from 'src/hooks/money.hook';
import { usePageFound } from 'src/hooks/page-found.hooks';
import { rootSnapshot } from 'src/stores/root.store';

import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';

function useDebounceEffect(effect: any, deps: DependencyList, delay = 250) {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

export const ProductPage = observer(() => {
  const { translate } = useTranslate();
  document.title = `${translate(`ProductPage.title`)} | ${process.env.REACT_APP_BRAND_NAME}`;

  const { id } = useParams<IdParam>();
  const [pageFound] = usePageFound();
  const [formatMoney] = useMoney();
  const categoryNavigation = useCategoryNavigation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPartsLoading, setIsPartsLoading] = useState<boolean>(false);
  // const [partsRequired, setPartsRequired] = useState<Array<RequiredPartsModel> | undefined>();
  const { basketId }: any = history.location.state || {};

  const idParam = id ? Number(id) : 0;

  const {
    customization: {
      parts,
      currentProduct,
      currentProductParts,
      fetchProduct,
      fetchProductParts,
      getFinalPrice,
      quantity,
      setInitialCustomization,
      setBasketCustomization,
      refreshCustomization,
      required,
      priceWithTax
    },
    restaurant: { currentRestaurant },
  } = useStore();
  const store = useStore();

  const isFirstRender = useRef(true);

  const footerRef = useRef();

  useEffect(
    () => {
      const isDifferentProduct = currentProduct === undefined || currentProduct.code !== idParam || isFirstRender.current || basketId;
      const productUnavailable =
        currentRestaurant.outageProducts &&
        currentRestaurant.outageProducts.find(
          (outage: RestaurantOutageProductsObject) => outage.productCode === idParam,
        );
      if (isDifferentProduct) {
        if (isFirstRender.current) {
          applySnapshot(store.customization, {
            quantity: 1,
            parts: [],
          });
        }
        isFirstRender.current = false;
        if (basketId) {
          fetchProduct(idParam, true)
            .then((product: ProductObject) => {
              if (product.type === ProductType.COUPON) throw new Error();
            })
            .then(() => fetchProductParts(idParam))
            .then(() => setInitialCustomization())
            .then(() => setBasketCustomization(basketId))
            .then(() => refreshCustomization())
            .then(() => {
              rootSnapshot('customization').saveInitialState();
            })
            .then(() => {
              setIsLoading(false);
            })
        } else {
          fetchProduct(idParam, true)
            .then((product: ProductObject) => {
              if (product.type === ProductType.COUPON) throw new Error();
            })
            .then(() => fetchProductParts(idParam))
            .then(() => setInitialCustomization())
            .then(() => {
              rootSnapshot('customization').saveInitialState();
            })
            .then(() => {
              // setPartsRequired(required);
              setIsLoading(false);
            })
            .catch((error: any) => {
              console.log(error);
              history.replace('/404');
            });
        }
      } else {
        if (currentProduct.type === ProductType.COUPON || productUnavailable) {
          history.replace('/404');
        }
        setIsLoading(false);
        if (required) {
          // setPartsRequired(required);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idParam, basketId],
  );

  // const productPrice = useMemo(() => {
  // 	return finalPrice ? finalPrice : currentProduct && currentProduct.price ? currentProduct.price.defaultUnitPrice : 0;
  // }, [finalPrice, currentProduct]);
  const finalPrice = getFinalPrice(false);
  const productPrice = finalPrice ??
    (currentProduct && currentProduct.price
      ? finalPrice
      : 0);

  const currentParts = parts.map((part) => [part.parent, part.qty, part.product.code].join('--')).join('___');

  useDebounceEffect(() => {
    if (!isLoading) {
      setIsPartsLoading(true);
      fetchProductParts(idParam).then(() => {
        refreshCustomization()
        setIsPartsLoading(false);
      }).catch(() => console.log)
    }
  }, [currentParts, idParam], 500)

  useEffect(() => {
    if (!isLoading) {
      setIsPartsLoading(true);
    }
  }, [currentParts, idParam])

  useLayoutEffect(() => {
    console.log('hmmm', footerRef.current);
    function updateSize() {
      if (footerRef.current) {
        document.querySelector('footer')!.style.paddingBottom = `calc(${footerRef.current.clientHeight}px + 10px)`
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
      document.querySelector('footer')!.style.paddingBottom = '0px';
    }
  })

  return (
    <main className="product">
      {isLoading ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="product__container theme-container">
          {categoryNavigation}
          {pageFound('PRODUCT', idParam) && (
            <>
              <ProductInfo price={productPrice} />
              {currentProductParts && currentProductParts.length > 0 && <PartsContainer />}
              <Portal>
                <div ref={footerRef} className="product__actions product-actions product-actions--sticky">
                  <div className="product-actions__container theme-container">
                    {productPrice && (
                      <p className="product-actions__total product__total txt-l txt-lemibold txt-secondary-dark">
                        {'Total: '}
                        {formatMoney(productPrice)}
                      </p>
                    )}
                    {required === undefined ? (
                      <LoadingSpinner height={'40px'} width={'40px'} />
                    ) : (
                      <AddToCartButton
                        code={currentProduct ? currentProduct.code : emptyProduct.code}
                        quantity={
                          currentProduct ? (quantity ? quantity : 1) : emptyProduct.quantity
                        }
                        action={basketId ? ActionType.UPDATE : ActionType.ADD}
                        basketId={basketId}
                        partsRequired={required}
                        disabled={isLoading || isPartsLoading}
                      />
                    )}
                  </div>
                </div>
              </Portal>
            </>
          )}
        </div>
      )}
    </main>
  );
});
