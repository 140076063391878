import {
  BasketProductModelType,
  ComponentNameTypes,
  CustomizationStore,
  PopoverTypes
} from '@acrelec-cloud/apico-cdk';
import { ProductObject, RestaurantOutageProductsObject } from '@acrelec-cloud/apico-sdk';
import { includes } from 'lodash';
import { observer } from 'mobx-react-lite';
import { applySnapshot, getEnv, getSnapshot } from 'mobx-state-tree';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';

import { LoadingSpinner } from 'src/components/LoadingSpinner/LoadingSpinner';
import { usePopover } from 'src/components/Popover/popover.hook';
import { ProductCustomTile } from 'src/components/Products/Product/ProductItem/ProductCustomTile';
import { useStore } from 'src/contexts/store.context';

import { ProductChoice } from './ProductChoice';
import { ProductTile } from './ProductTile';

interface ProductItemProps {
  item: ProductObject;
  productToDisplay: number | undefined;
  setProductToDisplay: (code: number) => void;
  enableAddToCart?: boolean;
}
export const ProductItem = observer((props: ProductItemProps) => {
  const store = useStore();
  const { item, productToDisplay, setProductToDisplay, enableAddToCart = false } = props;
  const { dimensions, type } = item;
  const [displayChoiceTile, setDisplayChoiceTile] = useState(false);
  const history = useHistory();
  const { openPopover } = usePopover();

  // const isCombo = includes(type, 'COMBO');
  const hasDimensions = dimensions.length > 0;
  const meal = hasDimensions && dimensions.find((dimension) => dimension.dimensionChar === 'L');
  // const shouldDisplaySelect = !isCombo && hasDimensions && !!meal;
  const shouldDisplaySelect = false;

  const {
    restaurant: { currentRestaurant },
    basket: { addBasketProduct },
  } = useStore();

  const productUnavailable = !currentRestaurant || (
    currentRestaurant.outageProducts &&
    currentRestaurant.outageProducts.find(
      (outage: RestaurantOutageProductsObject) => outage.productCode === item.code,
    ));

  const handleProductAction = useMemo(() => {
    if (productUnavailable) return false; // This product is unavailable
    const onDisplaySelect = () => {
      setDisplayChoiceTile(true);
      setProductToDisplay(item.code);
    };
    const onNavigate = () => history.push(`/product/${item.code}`);
    return isMobile
      ? {
        onClick: () => (shouldDisplaySelect ? onDisplaySelect() : onNavigate()),
      }
      : {
        onMouseEnter: () => onDisplaySelect(),
        onClick: () => (shouldDisplaySelect ? undefined : onNavigate()),
      };
  }, [history, item.code, productUnavailable, setProductToDisplay, shouldDisplaySelect]);

  useEffect(() => {
    if (productToDisplay !== item.code) {
      setDisplayChoiceTile(false);
    }
  }, [productToDisplay, setDisplayChoiceTile, item]);

  const [customizationLoading, setCustomizationLoading] = useState<boolean>(false);


  const onAddToCart = useCallback(async () => {
    setCustomizationLoading(true);

    const loadCustomization = async () => {
      const tempStore = CustomizationStore.create(
        {
          quantity: 1,
        },
        {
          ...getEnv(store),
          root: store,
        },
      );

      await tempStore.fetchProduct(item.code);
      await tempStore.fetchProductParts(item.code);
      tempStore.setInitialCustomization();

      return tempStore;
    };

    const customization = await loadCustomization();

    setCustomizationLoading(false);

    if (!customization || customization.required.length > 0) {
      history.push(`/product/${item.code}`);
      return;
    }

    const currentCustomization: BasketProductModelType = getSnapshot(customization);

    const maxQtyProduct = 99999; // Unlimited quantity
    applySnapshot(store.customization, currentCustomization);
    addBasketProduct(currentCustomization, maxQtyProduct);

    openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket);
  }, [addBasketProduct, openPopover, history, item]);

  return (
    <div className="product-item hover-parent" {...handleProductAction}>
      {shouldDisplaySelect && displayChoiceTile && meal && (
        <div className="hover-child">
          <ProductChoice mealCode={meal.productCode} itemCode={item.code} />
        </div>
      )}
      <ProductTile item={item} onAddToCart={enableAddToCart ? onAddToCart : undefined} />
      {customizationLoading
        && <div className="product-item__loading">
          <LoadingSpinner></LoadingSpinner>
        </div>}
    </div>
  );
});
