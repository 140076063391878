import { BrandBannerObject } from '@acrelec-cloud/apico-shared';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import quickFirstDefaultImage from 'src/assets/images/quick-default-banner_1.jpg';
import quickSecondDefaultImage from 'src/assets/images/quick-default-banner_2.jpg';
import quickThirdDefaultImage from 'src/assets/images/quick-default-banner_3.jpg';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { LoadingSpinner } from 'src/components/LoadingSpinner/LoadingSpinner';
import { RestaurantFinder } from 'src/components/Restaurant/RestaurantFinder';
import { SliderImage } from 'src/components/Slider/SliderImage';
import { useStore } from 'src/contexts/store.context';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.scss';

export const HomeMenuPage = observer(() => {
  const { translate } = useTranslate();
  document.title = `${translate('HomeMenuPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

  const {
    auth: { isLogged },
    brand: { fetchBanners, banners },
  } = useStore();

  const [displayError, setDisplayError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Default Quick banners
  const quicksBanners = [
    { id: 1, url: quickFirstDefaultImage },
    { id: 2, url: quickSecondDefaultImage },
    { id: 3, url: quickThirdDefaultImage },
  ];
  const brandName = process.env.REACT_APP_BRAND_NAME;

  useEffect(() => {
    fetchBanners().then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const homeContent = useMemo(() => {
    return isLogged ? (
      <div className="home-main__btn-container">
        <Link to="/order" className="home-main__btn btn__primary">
          {translate('HomeMenuPage.button')}
        </Link>
      </div>
    ) : (
      <div className="home-main__login">
        {displayError && <p>{translate(`Profile.error`)}</p>}
        <RestaurantFinder onError={setDisplayError}></RestaurantFinder>
      </div>
    );
  }, [displayError, isLogged, translate]);

  return (
    <main className="home-main">
      <div className={isLogged ? 'home-main__content--center' : 'home-main__content'}>
        <div
          className={isLogged ? 'home-main__content--center--logged' : 'home-main__content__left'}>
          {!isLoading ? (
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{
                el: '.my-custom-pagination-div',
                clickable: true,
                bulletClass: `swiper-pagination-bullet`,
                bulletActiveClass: 'swiper-pagination-bullet-active',
              }}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              observer
              observeSlideChildren
              onSwiper={swiper => {
                setInterval(() => {
                  swiper.update(); // ------> this solution
                }, 300);
              }}

              className="home-main__content__left__swiper">
              <div className="my-custom-pagination-div" />
              {brandName === 'Quick' ? (
                quicksBanners.map((banner: any) => {
                  return (
                    <SwiperSlide key={banner.id} className="swiper-slide">
                      <SliderImage src={banner.url} />
                    </SwiperSlide>
                  );
                })
              ) : (
                <div>
                  {banners && banners.banners && banners.banners.length > 0 ? (
                    banners.banners.map((banner: BrandBannerObject) => {
                      return (
                        <SwiperSlide key={banner.id} className="swiper-slide">
                          <SliderImage src={banner.url} />
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <SwiperSlide className="swiper-slide">
                      <SliderImage src={"https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=100w 100w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=300w 300w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=500w 500w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=750w 750w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=1000w 1000w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=1500w 1500w, https://images.squarespace-cdn.com/content/v1/6250b3f664407c6f79c054e9/21c77d10-dfce-41f4-8e4e-a1735ed83e94/DSC05476.jpg?format=2500w 2500w"} />
                    </SwiperSlide>
                  )}
                </div>
              )}
            </Swiper>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        {homeContent}
      </div>
    </main >
  );
});
