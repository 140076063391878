import {observer} from 'mobx-react-lite';
import qs from 'qs';
import React, {useState} from 'react';
import {usePlacesWidget} from 'react-google-autocomplete';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useGeolocation} from 'src/hooks/geolocation.hook';

export const SearchRestaurant = observer(() => {
	const {translate} = useTranslate();
	const {cancelLocationByAddress} = useGeolocation();
	const [address, setAddress] = useState<string>('');
	const history = useHistory();
	const {
		restaurant: {fetchRestaurants},
	} = useStore();

	const {ref} = usePlacesWidget({
		onPlaceSelected: (place) => {
			setAddress(place.formatted_address!);
			if (place.geometry) {
				history.push(
					`/restaurants?${qs.stringify({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					} as any)}`,
				);
			}
		},
		options: {
			types: ['(regions)'],
			componentRestrictions: {country: 'us'},
		},
	});

	const cancelSearch = async () => {
		const hasSearchRunning = await cancelLocationByAddress;
		if (hasSearchRunning) cancelLocationByAddress();
		setAddress('');
	};

	return (
		<div className="restaurants__search search bg-secondary-dark">
			<div className="search__field bg-white">
				<input
					ref={ref as any}
					type="text"
					className="search__input txt-s txt-grey-90"
					placeholder={translate('RestaurantsPage.search.placeholder')}
					value={address}
					onChange={({currentTarget}) => setAddress(currentTarget.value)}
				/>
				<button className="search__button search__button--search txt-grey-80" title="search">
					<i className="fas fa-search" title="search"></i>
				</button>
				{/* <button
					className="search__button search__button--clear txt-grey-80"
					onClick={() => cancelSearch()}
					title="clear"
					disabled={!address}>
					<i className="fas fa-times" title="clear"></i>
				</button> */}
			</div>
		</div>
	);
});
