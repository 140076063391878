import {observer} from 'mobx-react-lite';
import React from 'react';

import {usePopover} from 'src/components/Popover/popover.hook';
import {useStore} from 'src/contexts/store.context';

import {CustomPopovers, DisplayTypes, POPOVER_DISPLAY_CLASS} from './custom-popovers.config';

export const PopoverComponent = observer(() => {
	const {closePopover} = usePopover();

	const {
		popover: {display, type, componentName},
	} = useStore();

	const CustomComponent = CustomPopovers[componentName!];

	const displayClass = () => {
		switch (display) {
			case DisplayTypes.OPEN:
				document.body.classList.add(POPOVER_DISPLAY_CLASS);
				return ` popover__active--${type}`;
			case DisplayTypes.CLOSING:
				return ` popover__inactive--${type}`;
			case DisplayTypes.CLOSED:
				document.body.classList.remove(POPOVER_DISPLAY_CLASS);
				return '';
		}
	};

	const handleContent = () => {
		if (display === DisplayTypes.OPEN || display === DisplayTypes.CLOSING) {
			return <CustomComponent />;
		}
		return <></>;
	};

	return (
		<div className={`popover${displayClass()}`}>
			<div className="popover__background" onClick={() => closePopover()}></div>
			<div className="popover__box">
				<div className="popover__close" onClick={() => closePopover()}></div>
				<div className="popover__body">{handleContent()}</div>
			</div>
		</div>
	);
});
