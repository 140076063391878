import { findPrice } from '@acrelec-cloud/apico-cdk';
import { ProductObject, RestaurantOutageProductsObject } from '@acrelec-cloud/apico-sdk';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import addToCart from 'src/assets/images/add-to-cart-stars.svg';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { useStore } from 'src/contexts/store.context';
import { useImage } from 'src/hooks/image.hook';
import { useMoney } from 'src/hooks/money.hook';
import { useOpeningHours } from 'src/hooks/opening-hours.hook';

interface ProductTileProps {
  item: ProductObject;
  onAddToCart?: (code: number) => void;
}

export const ProductTile = observer((props: ProductTileProps) => {
  const { item, onAddToCart } = props;
  const [formatMoney] = useMoney();
  const { image } = useImage(item.imageUrl);
  const { translate, translateProduct } = useTranslate();
  const { isOpen } = useOpeningHours();;

  const {
    navigation: { getProductPrice },
    restaurant: { orderingDisabled, currentRestaurant },
  } = useStore();

  const productUnavailable =
    currentRestaurant.outageProducts &&
    currentRestaurant.outageProducts.find(
      (outage: RestaurantOutageProductsObject) => outage.productCode === item.code,
    );

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      onAddToCart && onAddToCart(item.code);
    },
    [onAddToCart, item],
  );

  return (
    <div className="product-item__button">
      <div className="product-item__container">
        <div className="product-item__image-container">
          {/* <img src={"https://placehold.co/600x400/white/orange?text=Placeholder"} alt={translateProduct(item).name} className="product-item__image" /> */}
          <img src={image} alt={translateProduct(item).name} className="product-item__image" />
        </div>
        <div className="product-item--infos txt-xleft">
          <h2 className="txt-xleft product-item--title">{translateProduct(item).name}</h2>
          {!!item.calories && (
            <span className="txt-s txt-secondary">{`${item.calories}  ${translate(
              `App.calories`,
            )}`}</span>
          )}
          <div>
            {item.price && (
              <div className="product-item--price txt-xxs txt-bold">
                <span className="txt-tertiary txt-m">{formatMoney(findPrice(item))}</span>
              </div>
            )}
            {!productUnavailable && onAddToCart && (
              <button
                className={cx('product-item--price add-to-cart txt-xxs txt-bold bg-orange', (!isOpen || orderingDisabled) && "product-item--price--disabled")}
                onClick={onClick}
                disabled={!isOpen || orderingDisabled}>
                <ReactSVG src={addToCart} width={30} height={30} />
              </button>
            )}
          </div>
        </div>
        {productUnavailable && (
          <div className="product-item__unavailable">
            <p>{translate(`App.unavailable`)}</p>
          </div>
        )}
      </div>
    </div>
  );
});
