import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { GoBack } from 'src/components/Order/GoBack';
import { CheckoutPageInfos } from 'src/components/Payment/CheckoutPageInfos';
import { CheckoutPageRecap } from 'src/components/Payment/CheckoutPageRecap';
import { SelectHourComponent } from 'src/components/PickUp/SelectHourComponent';
import { useStore } from 'src/contexts/store.context';
import { useOpeningHours } from 'src/hooks/opening-hours.hook';

export const CheckoutPage = observer(() => {
  const { translate } = useTranslate();
  const {
    restaurant: { currentRestaurant },
  } = useStore();
  const {
    basket: { setPickUpTime },
  } = useStore();
  document.title = `${translate('CheckoutPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isOpen } = useOpeningHours();

  return (
    <main className="checkout-page-main">
      <div className="theme-container checkout-page">
        <GoBack />
        {isOpen(currentRestaurant) ? (
          <>
            <h1 className="txt-bold txt-primary txt-uppercase txt-xxl">
              {translate('CheckoutPage.title')}
            </h1>
            <div>
              <div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
                <SelectHourComponent
                  title={translate('SelectHourComponent.title')}
                  callback={setPickUpTime}
                />
              </div>
            </div>
            <div className="checkout-page__body">
              <div className="checkout-page__column checkout-page__column--right">
                <CheckoutPageRecap />
              </div>
              <div className="checkout-page__column checkout-page__column--left">
                <CheckoutPageInfos />
              </div>
            </div>
          </>
        ) : (
          <div>
            <p className="checkout-page__restaurant-closed txt-l txt-bold txt-error">
              {translate('CheckoutPage.restaurant_closed')}
            </p>
          </div>
        )}
      </div>
    </main>
  );
});
